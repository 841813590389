import React, { useState } from "react";
import { FormattedHTMLMessage, useIntl } from "gatsby-plugin-intl";
import { GTM_TAGS, PARTNER_DETAILS, PARTNER_COUNTRY } from "../../utils/constants";
import { gtmEventTrack } from "../../utils/utilities";
import "./submitClaim.scss";

function SubmitClaim() {
    const intl = useIntl();
    const languageParam = useState('EN');
    const isIEEIR = process.env.GATSBY_PARTNER === PARTNER_DETAILS.EIR && (process.env.GATSBY_COUNTRY === PARTNER_COUNTRY.IE);
    const isSINGTEL = process.env.GATSBY_PARTNER === PARTNER_DETAILS.SINGTEL && (process.env.GATSBY_COUNTRY === PARTNER_COUNTRY.SG);
    const isTRE = process.env.GATSBY_PARTNER === PARTNER_DETAILS.TRE && (process.env.GATSBY_COUNTRY === PARTNER_COUNTRY.SE);
    const navigateToPortal = () => {
        if (
            GTM_TAGS[process.env.GATSBY_PARTNER] &&
            GTM_TAGS[process.env.GATSBY_PARTNER][process.env.GATSBY_COUNTRY] &&
            GTM_TAGS[process.env.GATSBY_PARTNER][process.env.GATSBY_COUNTRY]
                .SERVICE_PORTAL
        ) {

            gtmEventTrack({
                ...GTM_TAGS[process.env.GATSBY_PARTNER][process.env.GATSBY_COUNTRY]
                    .SERVICE_PORTAL.CLICK,
                action: "Click",
            });
        }
    };
    return (
        <section>
            <div className="submit-claim-wrap bg-white">
                <div className="submit-claim-inner">
                    <div className="submit-claim-flex text-black">
                        <div className="submit-claim-header">
                            <p className="submit-claim-heading"><FormattedHTMLMessage id="submitClaimHeader" /></p>
                            <p className="submit-claim-text"><FormattedHTMLMessage id="submitClaimSubHeader" /></p>
                        </div>
                        <div className="submit-claim-opt-wrap">
                            <div className="submit-claim-opt-inner">
                                <a
                                    className="submit-claim-btn"
                                    type="button"
                                    data-track-btn="sPortal"
                                    id="navToPortal"
                                    onClick={navigateToPortal}
                                    href={`${process.env.GATSBY_CLAIMS_PAGE_LINK}&language=${languageParam}`}
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    {intl.formatMessage({ id: "portalBtn" })}
                                </a>
                            </div>
                            {(isIEEIR || isTRE) && (<div className="submit-claim-opt-inner2">
                                <FormattedHTMLMessage id="theftFormNote" />
                            </div>)}
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>)

}
export default SubmitClaim;
